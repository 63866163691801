.useremail {
    font-size: 16px;
    margin-left: 15px;
}

.name-icon {
    width: 40px;
    height: 40px;
    color: #FFF;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    font-variant-caps: all-small-caps;
    padding: 7px;
    align-items: center;
}

.cardShadow {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background: #ffffff;
    padding: 15px;
    cursor: pointer;
}

.cardShadow:hover {
    box-shadow: 0px 5px 44px 0px rgba(0, 0, 0, 0.25);
    transition: .4s;

}

.cardCount {
    font-size: 46px;
    color: #101828;
    font-weight: 600;
}

.cardTitle {
    font-size: 22px;
    color: #315975;
    font-weight: 600;
}

.cardDesc {
    font-size: 14px;
    color: #101828;
    font-weight: 400;
    margin-bottom: 45px;
    min-height: 60px;
}

.cardLink {
    font-size: 14px;
    color: #363F72;
    font-weight: 600
}

.actionDesc {
    font-size: 14px;
    color: #101828;
}

.actionTitle {
    font-size: 20px;

}

.search {
    position: relative;
    color: #aaa;
    font-size: 16px;
}

.search {
    display: inline-block;
}

.search input {
    width: 320px;
    height: 44px;
    background: #fcfcfc;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

}

.search input {
    text-indent: 32px;
}

.search .fa-search {
    position: absolute;
    top: 14px;

}

.search .fa-search {
    left: 14px;
    right: auto;
}

.searchbox .filter {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    background-color: #D0D5DD !important;
    color: #3E4451;
}

.searchbox .filter:focus,
.searchbox .filter:active,
.searchbox .filter:hover {
    border: none;
    color: #3E4451;
}

.nav-item.nav-profile .dropdown .dropdown-toggle:after {
    display: none !important;
}

.option-list label {
    padding: 10px;
    display: flex;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
    border: 1px solid #315975 !important;
    padding: 13px 24px 13px 24px;
    border-radius: 8px 8px 0px 0px;
    color: #315975 !important;
    font-weight: 700;

}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
    text-transform: unset !important;
    padding: 13px 24px 13px 24px;
    font-family: unset;
    color: #828282 !important;
}

body {
    font-family: "Lato", sans-serif !important;
}

.custom-dropdown {
    width: 380px !important;
}

.fil-h4 {
    font-size: 20px;
    color: #101828;
    border-bottom: 1px solid #D5D5D5;
}

.fil-h3 {
    font-size: 16px;
    color: #101828;

}

.type-fil {
    border-bottom: 1px solid #D5D5D5;
}

.loadmore {
    color: #005284;
    font-size: 14px;
    font-weight: 900;
}

.maskid {
    color: #315975;
    font-weight: 700;
    cursor: pointer;
    text-decoration: underline;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {

    font-weight: 700;
    text-align: left !important;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
    text-transform: unset !important;
    font-family: "Lato", sans-serif;
    color: #828282 !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

}

.vertical-tabs button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
    min-height: unset !important;
}

.maskid .pending {
    height: 10px;
    width: 10px;
    border-radius: 50px;
    display: inline-block;
    background: rgba(251, 191, 36, 1);
    margin-right: 10px;

}

.maskid .overdue {
    height: 16px;
    width: 16px;
    border-radius: 50px;
    display: inline-block;
    background: rgba(238, 87, 36, 1);
    margin-right: 10px;

}

.table-action i {
    font-size: 25px;
    cursor: pointer;
    padding: 0px 5px;
}
.p-column-filter-overlay , .p-multiselect-panel{
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}
.headtitle{
 border-left-width: 2px;
    border-left-color: rgba(209, 213, 219, 1);
    border-left-style: solid;
}
.p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}
.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50% !important;
    height: 2.357rem;
}
.p-datatable-header{
    background-color: #ffffff;
}

.headerCount {
    background: #8d90937a;
    color: #fff;
    border-radius: 4px;
    padding: 6px;
    font-weight: bold;
    margin-left: 5px;
    font-size: 13px;
    display: flex;
    line-height: 1;
    align-items: center;
    justify-content: center;
  }
  button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected .headerCount {
    background-color: #005284;
  }
  span.MuiTabs-indicator {
    background-color: #005284 !important;
    height: 3px !important;
  
  }
  .calicon button{
    background-color: #005284;
    border: 1px solid #005284;
  } 
  
  .obs-head-title {
    color: rgba(16, 24, 40, 1);
    font-size: 24px;
    font-weight: 600;

}

.obs-id {
    font-size: 16px;
    color: rgba(55, 65, 81, 1);
    font-weight: 600;
}

.obs-head-dec {
    font-size: 14px;
}

.obs-head-color {
    color: rgba(16, 24, 40, 1);
}
.obs-dec{
    font-size: 16px;
}
.p-button{
    border-radius: 3px !important;
}