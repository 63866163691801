/* Utilities */

.grid-margin {
  margin-bottom: $card-spacing-y;
}

.grid-margin-sm-0 {
  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}

.grid-margin-md-0 {
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.grid-margin-lg-0 {
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}

.grid-margin-xl-0 {
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
}

.img-lg {
  width: 92px;
  height: 92px;
}

.img-sm {
  width: 43px;
  height: 43px;

  &.rounded-10 {
    border-radius: 10px;
  }
}

.img-xs {
  width: 37px;
  height: 37px;
}

.img-ss {
  width: 35px;
  height: 35px;
}

.stretch-card {
  @include display-flex;
  @include align-items(stretch);
  @include justify-content(stretch);

  >.card {
    width: 100%;
    min-width: 100%;
  }
}

.border-right-sm {
  @media (min-width: 576px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-md {
  @media (min-width: 768px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-lg {
  @media (min-width: 992px) {
    border-right: $border-width solid $border-color;
  }
}

.border-left-sm {
  @media (min-width: 576px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-md {
  @media (min-width: 768px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-lg {
  @media (min-width: 992px) {
    border-left: $border-width solid $border-color;
  }
}

.text-gray {
  color: #8c8c8c;
}

.text-black {
  color: $black;
}

.text-small {
  font-size: 12px;

  &.ps-4 {
    padding-left: 30px !important;
  }
}

.flex-grow {
  flex-grow: 1;
}

.font-weight-light {
  font-weight: $font-weight-light;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

.font-weight-normal {
  font-weight: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.image-grouped {
  display: flex;

  .text-avatar,
  img {
    @extend .img-ss;
    border-radius: 100%;
    margin-left: -10px;
    z-index: 0;
    border: 4px solid $card-bg;
    transform: scale(1);
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-property: "box-shadow", "z-index", "transform", "border-width";

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      z-index: 1;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      transform: scale(1.05);
      border-width: 0;

    }
  }

  .text-avatar {
    @extend .bg-inverse-primary;
    color: $primary;
    font-size: 11px;
    font-weight: 600;
  }
}

.dot-indicator {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.aligner-wrapper {
  position: relative;

  .absolute {
    position: absolute;

    &.absolute-center {
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.bottom {
      bottom: 0;
    }

    &.top {
      top: 0;
    }
  }
}

.v-strock-2 {
  width: 3px;
}

.min-width-cell {
  min-width: 110px;
}

.font-weight-medium {
  font-weight: 500;
}

.media {
  display: flex;
}

.settings-nav-bottom {
  position: fixed;
  bottom: 10px;
  width: 220px;
}

.h-250 {
  height: 25rem;
  overflow-y: auto;
}

.h-100 {
  height: 65vh !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.list.active {
  background: #ffe2e2;
  box-shadow: 0 3px 4px #c2c2c2;
}

.list {
  .dropdown {
    .dropdown-toggle:after {
      content: none;
    }
  }
}

.z-0 {
  z-index: 0 !important;
}

.shadow {
  box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
}

.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
  margin: 0;
}

.MuiTypography-root {
  font-family: Manrope, sans-serif;
}

.modal {
  --bs-modal-width: 800px;
}

.zoom-on-hover {
  transition: transform 0.3s ease-in-out;
  /* Smooth transition effect */
}

.zoom-on-hover:hover {
  transform: scale(1.05);
  /* Zoom in 1.1 times */
}


.animate-col-md-6 {
  transition: width 0.3s ease-in-out;
  /* Add transition property for width */
}

.animate-d-none {
  width: 0;
  /* Set width to 0 when hidden */
  overflow: hidden;
  /* Hide the content when width is 0 */
}

tr.MuiTableRow-root {
  height: inherit !important;
}

.table td {
  text-wrap: initial !important;
}

.header-blue {

  background: #3771be;
  color: white;

}

.body-blue {
  background: #fff;
}

.not-print .body-blue {
  background: #fff;
}

.header-logo {
  background-color: white;
  padding: 1rem !important;
  border-radius: 10px;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.white-bg-border-radius-10 {
  background-color: white;
  border-radius: 10px;
}

.form-control-plaintext {
  font-size: 16px;
  padding: 0.175rem;
  margin-bottom: 10px;
}

.img-checkbox {
  position: absolute;
  top: 30px;
  right: 0;
}

.font-lg {
  font-size: 18px !important;
}

.table thead th,
.table tbody td,
.table tbody th,
.table tr,
.table td,
.table th,
.text-16 {
  padding: 4px !important;
  font-size: 16px !important;
}

.text-16 {
  font-size: 16px;
}

.table td ul {
  margin: 0px;
}

.react-photo-gallery--gallery>div>img {
  flex-flow: nowrap !important;
  width: 150px;
  height: 150px;
}

.MuiDropzoneArea-root {

  min-height: 150px;
}

.table ul li,
.table ol li {
  font-size: 16px;
}

input.form-control {
  height: 38px;
  margin-bottom: 0px;
}

.m-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.not-print .col-form-label {
  font-size: 12px;
  font-weight: bold;
}

.not-print .table-header-sm tr th {
  font-size: 12px !important;
  font-weight: bold !important;
}

.not-print .padding-table.table tr {
  padding: 9px !important;
  font-size: 16px !important;
}

.not-print .text-strong {
  font-weight: bold;
}

.react-select__control {
  margin-bottom: 15px;
}

.react-pdf__Page {
  border-bottom: 7px solid #f4f5f7;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

.react-pdf__Page {
  background-color: #f4f5f7 !important;
}

.h-250 {
  height: 25rem;
  overflow-y: auto;
}

.h-250::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F5F5F5;
}

.h-250::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.h-250::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #1F3BB3;
}

.total-value {
  text-align: right;
  margin-right: 231px;
}

.medium-modal .modal-lg {
  --bs-modal-width: 50% !important;
}

.obs-content,
.obs-title {
  font-size: 16px;

  font-weight: bold;
}

.obs-title {
  color: darkgray;
}

.obs-header {
  background-color: #F9FAFB;
}

.obs-section {
  border-bottom: 1px solid #eee;
}

.font-sm.table tbody td,
.font-sm.table thead th {
  font-size: 12px !important;
}

.nested-modal {
  --bs-backdrop-zindex: 1055;
  z-index: 1055;
}

.required:after {
  content: " *";
  color: red;
}


.pack-container {
  margin: 20px auto;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.pack-header {

  align-items: center;
  border-bottom: 2px solid #007BFF;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.pack-header-image {
  width: 150px;
  height: auto;
  margin-right: 20px;
}

.pack-header-text {
  flex: 1;
}

.pack-header-text h1 {
  margin: 0;
  font-size: 1.2em;
  color: #007BFF;
}

.pack-header-text p {
  margin: 5px 0;
  font-size: 0.9em;
}

.pack-content {
  margin-top: 20px;
}

.pack-section {

  padding: 10px;
  background-color: #ffffff;
}

.pack-section h2 {
  margin-bottom: 10px;
  font-size: 1em;
  color: #007BFF;
}

.pack-section ul {
  padding-left: 20px;
  font-size: 0.9em;
}

.pack-section ul li {
  margin-bottom: 10px;
}

.pack-risk-level {
  font-weight: bold;
  color: red;
  font-size: 0.9em;
}


.acc-design {
  border: 1px solid #D1D5DB;
  margin-bottom: 20px;
  box-shadow: none !important;
}

.unitCard.active {
  border: 1px solid #005284;
  background: #F2F2F2;
  border-radius: 3px;
}

.unitCard.completed {
  // border: 1px solid #005284;
  background: #01954d;
  color: #070000;
  border-radius: 3px;
  font-size: 14px;
}

.step {
  height: 5px;
  background: #d3d3d3;
  margin: 4px;
  border-radius: 10px;
}

.active.step,
.done.step {
  background-color: #B2328A;
}

.steps {
  display: flex;
}

.step-btn {
  // position: fixed;
  // background: #fff;
  // box-shadow: 0 0 5px 0 hsla(0, 0%, 75.3%, .74);
  // padding: 10px;
}

.index,
.step-btn {
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
}

.step-btn button {

  color: #fff;
  text-decoration: none;
  background: #B2328A;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  width: 100%;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .6px;
  border: 1px solid #B2328A;
}

.step_back_btn {

  color: #005284 !important;
  text-decoration: none !important;
  background: #fff !important;
  border-radius: 3px !important;
  outline: none !important;
  cursor: pointer !important;
  width: 30% !important;
  margin: 2px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: .6px !important;
  border: 1px solid #005284;
}

.bg-green {
  background-color: #005EAC !important;
  border: none !important;
}

.horizontal {
  margin: 0 0 26px;
  padding: 16px;
  background: #f3f3f3 !important;
}

.embed iframe {
  max-width: 100%;
}

.read-or-hide {
  color: blue;
  cursor: pointer;
}

// .card-img-top{
//   width: unset !important;
// }

.sidebar-learn {
  position: relative;

  /* Hide the sidebar by default */

  height: 100%;
  overflow-x: hidden;
  transition: 0.3s;
  padding-top: 60px;
  z-index: 1000;
}

.sidebar-learn.open-learn {
  left: 0;
  /* Move the sidebar into view */
}

.sidebar-learn .close-btn-learn {
  position: absolute;
  top: 20px;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  background: none;
  border: none;
  color: #818181;
  cursor: pointer;
}

.sidebar-content-learn {
  color: white;
  text-align: center;
}

.sidebar-content-learn h2 {
  margin-top: 0;
}

.sidebar-content-learn ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-content-learn ul li {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar-content-learn ul li:hover {
  color: #f1f1f1;
}

.open-btn-learn {
  font-size: 20px;
  cursor: pointer;

  color: white;
  padding: 10px 15px;
  border: none;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1001;
}

.login-option {
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 340px;
}

.login-option:hover {
  background-color: #f0f0f0;
}

.logo {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.certificate-container {
  display: flex;
  justify-content: center;
  border: 3px solid #96196b;
  ;
  padding: 0;
  margin-bottom: 30px;
  background-image: url('../images/a.png');
  /* Adjust the path as needed */
  background-size: cover;
  background-repeat: no-repeat;
  width: 800px;
  height: 600px;
}

.certificate-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.certificate-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.koachlogo {
  height: 107px;
  background: white;
}

.certificate-title {
  color: #9D1E6B;
  font-size: 50px;
  font-weight: 600;
  text-align: center;
}

.certificate-subtitle {
  text-align: center;
  font-size: 25px;
}

.certificate-body {

  text-align: center;
  font-size: 22px;
}

.certificate-body p {
  font-size: 27px;
  line-height: 40px;
}

.highlight {
  text-decoration: underline;
}

.certificate-footer {
  text-align: center;
}

/* Loader styles */
.pretext-fetch-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pretext-fetch-loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.progress-bar-custom {
  height: 13px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.unitCard.completed.red {
  background-color: #eb303080;
}

.unitCard.completed.green {
  background-color: #01954d80;
}

/* LandingPage.css */

.starburst {
  position: relative;
  display: inline-block;
  background-color: #1E2E5C;
  color: white;
  font-size: 24px;
  font-weight: bold;
  padding: 20px 30px;
  clip-path: polygon(50% 0%, 55% 25%, 80% 20%, 65% 35%, 85% 50%, 65% 65%,
      80% 80%, 55% 75%, 50% 100%, 45% 75%, 20% 80%, 35% 65%,
      15% 50%, 35% 35%, 20% 20%, 45% 25%);
  text-align: center;
}

.confirm-button-custom {
  background-color: #4CAF50;
  /* Your custom color */
  color: white;
  /* Custom text color */
  border: none;
  /* Remove border if necessary */
  padding: 10px 20px;
  /* Custom padding */
  font-size: 16px;
  /* Custom font size */
  border-radius: 5px;
  /* Rounded corners if desired */
  cursor: pointer;
  margin-left: 10px;
  /* Pointer cursor on hover */
}

.cancel-buttton-custom {
  background-color: #eb3030;
  /* Your custom color */
  color: white;
  /* Custom text color */
  border: none;
  /* Remove border if necessary */
  padding: 10px 20px;
  /* Custom padding */
  font-size: 16px;
  /* Custom font size */
  border-radius: 5px;
  /* Rounded corners if desired */
  cursor: pointer;
   
}


/* PrivacyPolicy.css */
.privacy-policy-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  line-height: 1.8;
  color: #333;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.privacy-header {
  text-align: center;
  margin-bottom: 30px;
}

.privacy-header h1 {
  font-size: 2.5rem;
  color: #444;
}

.last-updated {
  font-size: 0.9rem;
  color: #888;
}

.privacy-section {
  margin-bottom: 20px;
}

.privacy-section h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #0056b3;
}

.privacy-section p, .privacy-section ul {
  font-size: 1rem;
  margin: 0 0 10px 0;
}

.privacy-section ul {
  padding-left: 20px;
}

.privacy-section ul li {
  margin-bottom: 8px;
}

.privacy-footer {
  text-align: center;
  margin-top: 30px;
  font-size: 0.9rem;
  color: #666;
}

.privacy-footer a {
  color: #0056b3;
  text-decoration: none;
}

.privacy-footer a:hover {
  text-decoration: underline;
}
/* CookieConsentPolicy.css */
.cookie-policy-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  line-height: 1.8;
  color: #333;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.cookie-header {
  text-align: center;
  margin-bottom: 30px;
}

.cookie-header h1 {
  font-size: 2.5rem;
  color: #444;
}

.cookie-section {
  margin-bottom: 20px;
}

.cookie-section h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #0056b3;
}

.cookie-section h3 {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #333;
}

.cookie-section p,
.cookie-section ul {
  font-size: 1rem;
  margin: 0 0 10px 0;
}

.cookie-section ul {
  padding-left: 20px;
}

.cookie-section ul li {
  margin-bottom: 8px;
}

.cookie-section a {
  color: #0056b3;
  text-decoration: none;
}

.cookie-section a:hover {
  text-decoration: underline;
}
.policy-links {
  text-align: center;
  margin-top: 20px;
}

.policy-link {
  font-size: 0.9rem;
  color: #007bff;
  text-decoration: none;
  margin: 0 10px;
}

.policy-link:hover {
  text-decoration: underline;
}

.divider {
  color: #ccc;
  font-size: 0.9rem;
}
